<template>
  <div id="chart" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Echart",
  data() {
    return {
      chart: null,
      data: null,
      time: "",
    };
  },
  props: {
    option: {
      type: Object,
    },
    navtor: Boolean,
    date: Boolean,
    type: Number,
  },
  created() {},
  mounted() {
    this.chart = echarts.init(this.$refs.chart);

    // if(this.option){
    //     this.chart.setOption(this.option);
    // }
    // else{
    //     //console.log(1111)
    //     this.chart.setOption(this.fakeData());
    // }
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    option(val) {
      this.chart.setOption(val);
    },
  },
  methods: {
    resize() {
      this.chart.resize();
    },
    fakeData() {
      var data = [82, 18, 19, 53, 69, 73, 31, 23, 42, 100, 90, 49];
      var dataName = [];
      for (var i = 0; i < data.length; i++) {
        dataName.push("西三路");
      }
      return {
        tooltip: {
          axis: true,
        },
        grid: {
          // left: 15,
          // right: 15,
          // bottom: 40,
          // top: 40,
        },
        xAxis: {
          type: "category",
          data: dataName,
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 45,
            interval: 0,
            align: "center",
            verticalAlign: "btoom",
            margin: 20,
          },
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            data: data,
            type: "bar",
            showBackground: true,
            barWidth: 12,
            backgroundStyle: {
              color: "#E0EBFF",
              borderRadius: [20, 20, 0, 0],
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#FF6611" },
                { offset: 1, color: "#FFAF83" },
              ]),
              borderRadius: [20, 20, 0, 0],
            },
            label: {
              show: true,
              position: "top",
              color: "#FF610B",
              fontSize: 15,
              fontWeight: "bold",
            },
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
#chart {
  width: 100%;
  height: 100%;
}
</style>
