<template>
  <div class="header_box">
    <div class="boxMenu">
      <div class="leftBox">
        <div class="timeClass">
          <div class="imgbox">
            <img
              class="imgTime"
              src="../../../../assets/Newicon/time@2x.png"
              alt=""
            />
          </div>
          <div class="timeDate">{{ nowtime }}</div>
        </div>
<!--        <div class="weather">-->
<!--          <div class="imgBoxwater">-->
<!--            <img-->
<!--              class="imgwater"-->
<!--              src="../../../../assets/Newicon/weather@2x.png"-->
<!--              alt=""-->
<!--            />-->
<!--          </div>-->
<!--          <div class="waterText">阵雨 &nbsp; 西北风3级</div>-->
<!--        </div>-->
      </div>
      <div class="rightBox">
        <div class="gotoBack" @click="goBack">返回</div>
        <div class="fullClass" @click="fullScreen">全屏</div>
        <div class="quitClass" @click="exit">退出</div>
        <div class="username">
          <img src="../../../../assets/Newicon/admin-icon.png" alt="">
          <span>{{userName}}</span>
        </div>
      </div>
    </div>
<!--    <div class="hello">-->
<!--      <div class="title-Name">-->
<!--        实时水位监测-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { logout } from "@/api/login";
import moment from "moment";

export default {
  name: "headerDataV",
  data() {
    return {
      userName: "",
      nowtime: "",
      timer: null,
    };
  },
  created() {
    this.userName = window.localStorage.getItem("userName");
  },
  mounted() {
    moment.locale("zh-cn");
    let _this = this;
    this.timer = setInterval(() => {
      _this.nowtime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss dddd");
    });
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 全屏
    fullScreen() {
      document.webkitExitFullscreen();
      document.documentElement.requestFullscreen();
    },
    // 退出
    async exit() {
      try {
        await this.$confirm("是否退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        const id = localStorage.getItem("id");
        logout({ id: id }).then((res) => {
          if (res.code == 200) {
            localStorage.clear();
            localStorage.setItem("remember", false);
            window.location.href = "/";
            this.$message.success("退出成功");
          } else {
            this.$message.error("服务器异常");
          }
        });
      } catch {}
    },
    // 返回
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./header.less";
</style>
