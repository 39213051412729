<!-- 大数据 -->
<template>
  <div class="bg_warpmap">
    <headerDataV></headerDataV>
    <div
      class="disappear"
      :class="showFlase == false ? 'disappearClass' : 'disappeartrue'"
      @click="Showother"
    >
      {{ mesTitle }}
    </div>
    <div
      class="identification"
      :class="showFlase == false ? 'identification' : 'identificationTrue'"
    ></div>
    <div class="Map">
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        :min-zoom="zoomMin"
        @click="getClickInfo"
        @ready="handler"
        :scroll-wheel-zoom="true"
      >
        <!--     :mapStyle="mapStyle"   -->
        <!--        <bm-label content="hahah"-->
        <!--                  :zIndex="9"-->
        <!--                  :labelStyle="labelStyle4"-->
        <!--                  :offset="{ width: 35, height: 6 }"/>-->

        <!-- 黄蔡河 -->
        <bm-polyline
          :path="Huangcaihe"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>

        <bm-label
          content="黄蔡河"
          :position="{ lng: 115.07191982186525, lat: 34.90711948010067 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
            paddingRight: '20px',
          }"
        />

        <!-- 北沙河 -->
        <bm-polyline
          :path="BeishaRiver"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="北沙河"
          :position="{ lng: 115.15078655411376, lat: 34.884303951350745 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
            paddingLeft: '10px',
          }"
        />

        <bm-label
          content="北沙河"
          :position="{ lng: 115.03230904040278, lat: 34.87873325270226 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
            paddingLeft: '10px',
          }"
        />

        <!--  东方红干渠 -->
        <bm-polyline
          :path="Dongfanghongmaincanal"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>

        <bm-label
          content="东方红干渠"
          :position="{ lng: 114.8194735159237, lat: 34.934767100523665 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
            paddingLeft: '10px',
          }"
        />

        <!-- 兰东干渠 -->
        <bm-polyline
          :path="Landongmaincanal"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="兰东干渠"
          :position="{ lng: 114.91305449242566, lat: 34.94381444703951 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 兰东干渠2 -->
        <bm-polyline
          :path="Landongmaincanal2"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="兰东干渠"
          :position="{ lng: 114.978581387851, lat: 34.98237457977539 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!--  兰开干渠 -->
        <bm-polyline
          :path="Lancaitrunkcanal"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>

        <!-- 兰杞干渠 -->
        <bm-polyline
          :path="Lanqimaincanal"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="兰杞干渠"
          :position="{ lng: 114.74992221181365, lat: 34.843045837574095 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />
        <bm-label
          content="兰杞干渠"
          :position="{ lng: 114.76420077789476, lat: 34.77710580155204 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 四明河 -->
        <bm-polyline
          :path="SimingRiver"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="四明河"
          :position="{ lng: 114.90774550686169, lat: 34.959857509068904 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <bm-label
          content="四明河"
          :position="{ lng: 115.13662476774897, lat: 34.96599948392229 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 贺李河 -->
        <bm-polyline
          :path="Helihe"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="贺李河"
          :position="{ lng: 115.11116229806865, lat: 34.835668712523365 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <bm-label
          content="贺李河"
          :position="{ lng: 115.24094947815026, lat: 34.81009173710209 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 张新河 -->
        <bm-polyline
          :path="Zhangxinhe"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="张新河"
          :position="{ lng: 115.10933424635584, lat: 34.83072803591696 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 魏东干渠 -->
        <bm-polyline
          :path="Weidongmaincanal"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="魏东干渠"
          :position="{ lng: 115.11135992528084, lat: 34.802126234587874 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 商丘干渠 -->
        <bm-polyline
          :path="Shangqiumaincanal"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>
        <bm-label
          content="商丘干渠"
          :position="{ lng: 115.01621589719153, lat: 34.81045850199603 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <bm-label
          content="商丘干渠"
          :position="{ lng: 114.83336581063551, lat: 34.84675266510707 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <!-- 金狮沟 -->
        <bm-polyline
          :path="WestBranchofduzhuangRiver"
          stroke-color="#419DEC"
          :stroke-opacity="0"
          :stroke-weight="4"
        ></bm-polyline>

        <bm-label
          content="金狮沟"
          :position="{ lng: 114.80208681277807, lat: 34.816993309380656 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <bm-label
          content="金狮沟"
          :position="{ lng: 114.81034673194233, lat: 34.78007811999621 }"
          :labelStyle="{
            color: '#fff',
            fontSize: '12px',
            background: 'none',
            border: 'none',
          }"
        />

        <div v-for="(item, i) in siteVos" :key="i">
          <!-- 在线 -->
          <template v-if="showSiteVos">
            <bm-marker
              v-if="
                item.deviceStatus == '0' &&
                item.waterLevelAlarmStatus == '0' &&
                item.accessControlStatus == '0' &&
                item.perimeterStatus == '0'
              "
              :position="{ lng: item.lng, lat: item.lat }"
              :icon="{
                url: require('@/assets/Newicon/zaixian.png'),
                size: { width: 35, height: 41 },
              }"
              :zIndex="9"
              @click="openInfoBox(item.lng, item.lat, item.id)"
            >
              <bm-label
                :content="item.siteName"
                :zIndex="9"
                :labelStyle="labelStyle1"
                :offset="{ width: 35, height: 6 }"
              />
            </bm-marker>

            <!-- 报警 -->
            <bm-marker
              v-if="
                item.deviceStatus == 0 &&
                (item.waterLevelAlarmStatus == 1 ||
                  item.accessControlStatus == 1 ||
                  item.perimeterStatus == 1)
              "
              :position="{ lng: item.lng, lat: item.lat }"
              :icon="{
                url: require('@/assets/Newicon/baojing.png'),
                size: { width: 35, height: 41 },
              }"
              :zIndex="9"
              @click="openInfoBox(item.lng, item.lat, item.id)"
            >
              <bm-label
                :content="item.siteName"
                :zIndex="9"
                :labelStyle="labelStyle4"
                :offset="{ width: 35, height: 6 }"
              />
            </bm-marker>
            <!-- 离线 -->
            <bm-marker
              v-if="item.deviceStatus == '1'"
              :position="{ lng: item.lng, lat: item.lat }"
              :icon="{
                url: require('@/assets/Newicon/lixian.png'),
                size: { width: 35, height: 41 },
              }"
              :zIndex="9"
              @click="openInfoBox(item.lng, item.lat, item.id)"
            >
              <bm-label
                :content="item.siteName"
                :zIndex="9"
                :labelStyle="labelStyle3"
                :offset="{ width: 35, height: 6 }"
              />
            </bm-marker>
          </template>
        </div>
      </baidu-map>
      <!--      <div ref="container" id="container" class="map"></div>-->
      <div v-show="showFlase" class="leftBox">
        <div class="left1">
          <div class="header_kqtj">
            <div class="StatisticsBox">
              <img
                class="imgbox"
                src="../../assets/Newicon/chartpie.png"
                alt=""
              />
              <span class="StatSpan">节制闸开启统计</span>
            </div>
            <div class="Historicalquery">
              <div class="textsapn">历史数据查询</div>
              <div class="classMont">
                <el-date-picker
                  v-model="montv"
                  @change="curSerch(montv)"
                  type="month"
                  placeholder="选择日期"
                  value-format="yyyy-MM"
                  :picker-options="pickerOptions"
                  popper-class="curSerchMount"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="echartsBox" style="width: 100%; height: 82%">
            <div style="width: 100%; height: 100%">
              <bingEchart
                class="echartsBox2"
                :option="chartData2"
                style="width: 100%; height: 100%"
              ></bingEchart>
            </div>
            <div class="totalL">
              <span class="textSpan">总开启次数</span>
              <div class="countSpan">{{ total }}</div>
            </div>
          </div>
        </div>
        <div class="left2">
          <div class="header_swqx">
            <div class="StatisticsBoxswqx">
              <img
                class="imgboxswqx"
                src="../../assets/Newicon/lineCharts.png"
                alt=""
              />
              <span class="StatisticsBoxSpan">实时水位曲线图</span>
            </div>
            <div class="Historicalqueryswqx">
              <div class="textsapn">历史数据查询</div>
              <div class="classMont">
                <el-date-picker
                  v-model="linemontv"
                  @change="lineSerch(linemontv)"
                  type="month"
                  placeholder="选择日期"
                  value-format="yyyy-MM"
                  :picker-options="pickerOptions"
                  popper-class="curSerchMount"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="swechartsBox" style="width: 100%; height: 82%">
            <div style="width: 100%; height: 82%">
              <bingEchart
                class="swechartsBox2"
                :option="chartData3"
                style="width: 441px; height: 265px"
              ></bingEchart>
            </div>
          </div>
        </div>
        <div class="left3">
          <div class="header_llzz">
            <div class="StatisticsBoxllzzt">
              <img
                class="imgboxllzzt"
                src="../../assets/Newicon/flowicon.png"
                alt=""
              />
              <span class="ssllzzt">实时流量柱状图</span>
            </div>
            <div class="Historicalquery">
              <div class="textsapn">历史数据查询</div>
              <div class="classMont">
                <el-date-picker
                  v-model="flowmontv"
                  @change="flowSerch(flowmontv)"
                  type="month"
                  placeholder="选择日期"
                  value-format="yyyy-MM"
                  popper-class="curSerchMount"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="lliangechartsBox" style="width: 100%; height: 82%">
            <div style="width: 100%; height: 82%">
              <bingEchart
                class="swechartsBox2"
                :option="chartData4"
                style="width: 441px; height: 240px"
              ></bingEchart>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showFlase" class="rightBox">
        <rightView1 class="right1con"></rightView1>
        <div class="right2con">
          <div class="header_anfang">
            <div class="anfnagStatisticsBox1">
              <img
                class="anfangimgbox"
                src="../../assets/Newicon/biaji.png"
                alt=""
              />
              <span class="textSpan">节制闸实时信息</span>
            </div>
            <div class="afHistoricalquery">
              <div class="Controlgatesapn"></div>
              <div class="ControlgateMont">
                <el-select
                  v-model="Selectedvalue"
                  @change="SelectSerach"
                  size="mini"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in stationBzList"
                    :key="item.value"
                    :label="index + 1 + ' ' + item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="contentMain">
            <div class="Beforegate">
              <div class="Befortext">
                <p style="font-size: 12px; color: #00cdff">警戒水位</p>
                <p style="font-size: 12px; color: #00cdff">
                  {{ siteItemVo.warningLevel }}cm
                </p>
              </div>
              <div class="Beforegateimg">
                <img class="imgsizeBefor" :src="imgBoxZQ" alt="" />
              </div>
              <div>
                <p
                  style="font-size: 12px"
                  :class="waterPole == true ? 'waterPoleClass' : ''"
                >
                  {{ siteItemVo.gateLevel }}cm
                </p>
                <p
                  style="font-size: 12px"
                  :class="waterPole == true ? 'waterPoleClass' : ''"
                >
                  闸前水位
                </p>
              </div>
            </div>
            <div class="Rearwaterlevel">
              <div style="height: 34px">
                <!--                <p style="font-size: 12px">警戒水位</p>-->
                <!--                <p style="font-size: 12px">{{siteItemVo.warningLevel}}cm</p>-->
              </div>
              <div class="Rearwaterlevelimg">
                <img class="imgsizeRear" :src="imgBoxZH" alt="" />
              </div>
              <div>
                <p style="font-size: 12px">{{ siteItemVo.currentLevel }}cm</p>
                <p style="font-size: 12px">闸后水位</p>
              </div>
            </div>
            <div class="Instantaneousflow">
              <div style="height: 34px"></div>
              <div class="flowimg">
                <img class="imgsizeflow" :src="ssllImg" alt="" />
              </div>
              <div>
                <p style="font-size: 12px">{{ siteItemVo.traffic }}m³/h</p>
                <p style="font-size: 12px">瞬时流量</p>
              </div>
            </div>
            <div class="Cumulativeflow">
              <div style="height: 34px"></div>
              <div class="Cumulativeimg">
                <img class="imgsizeCumulative" :src="ljllImg" alt="" />
              </div>
              <div>
                <p style="font-size: 12px">{{ siteItemVo.trafficSum }}m³</p>
                <p style="font-size: 12px">累计流量</p>
              </div>
            </div>
          </div>
          <div class="bottomBox">
            <div
              class="previousPage"
              @click="prevPage(zhadata.length, zhadata)"
            >
              <img src="../../assets/Newicon/leftPage.png" alt="" />
            </div>
            <div class="middleBox">
              <div class="middleImg">
                <div class="imgBoxMiddle">
                  <img class="imgSrcImg" :src="imgKs" alt="" />
                </div>
              </div>
              <div class="middleleft">
                <div class="oneMes">
                  <div class="one1">
                    <span class="gate1">{{ gateVo.gateName }} </span>高度{{
                      gateVo.gateHeight || siteItemVo.gateHeight
                    }}（cm）
                  </div>
                  <div class="one2">
                    <span class="gate2">{{ gateVo.gateName }}</span> 限高{{
                      gateVo.gateHeightLimit || siteItemVo.gateHeightLimit
                    }}（cm）
                  </div>
                </div>
                <div class="lineBox">
                  <el-progress
                    :percentage="gateVogateHeight"
                    class="ColorProgress"
                  ></el-progress>
                </div>
              </div>
            </div>
            <div class="nextPage" @click="nextPage(zhadata.length, zhadata)">
              <img src="../../assets/Newicon/rightPage.png" alt="" />
            </div>
          </div>
        </div>
        <div class="right3Con">
          <div class="header_baojing">
            <div class="baojingmesBox">
              <img
                class="imgboxbaojing"
                src="../../assets/Newicon/tongzh.png"
                alt=""
              />
              <span class="poliSpan">节制闸报警信息</span>
            </div>
            <div class="Historicalquery">
              <div class="textsapn">历史数据查询</div>
              <div class="classMont1">
                <el-date-picker
                  v-model="policeValue"
                  @change="police(policeValue)"
                  type="month"
                  placeholder="选择日期"
                  value-format="yyyy-MM"
                  :picker-options="pickerOptions"
                  popper-class="curSerchMount"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="baojingBox">
            <div v-if="noDisposeLists">
              <vue-seamless-scroll
                :data="noDisposeLists"
                :class-option="optionHover"
                class="seamless-warp"
              >
                <div
                  class="baojingmes"
                  v-for="(item, index) in noDisposeLists"
                  :key="'no' + index"
                >
                  <div>{{ index + 1 }}</div>
                  <div class="baojingimgmes">
                    <img
                      class="mesboximg"
                      src="../../assets/Newicon/exclamatorymark.jpg"
                      alt=""
                    />
                  </div>
                  <div class="siteName">
                    <NormalTextTooltipol
                      class="sitetName"
                      :text="item.siteName"
                    ></NormalTextTooltipol>
                  </div>
                  <div class="bjtype">
                    <NormalTextTooltipol
                      class="sitetypeName"
                      :text="item.typeName"
                    ></NormalTextTooltipol>
                  </div>
                  <div class="bjdate">{{ item.alarmTime }}</div>
                  <div class="btnxq" @click="detail(item)">详情</div>
                </div>
              </vue-seamless-scroll>
            </div>
            <div v-else style="margin: 0 auto">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerDataV from "./components/header/header";
import {
  getAllSiteList,
  getSiteByIdList,
  Turnonstatistics,
  SecurityBingLing,
  Waterleveldata,
  SecurityPage,
  Flowinterface,
} from "../../api";
import { getSitesList, getSitesIdList, getGateMessage } from "../../api/main";
import TMap from "../../../src/utils/initMap";
import bingEchart from "../../components/Echart/bingEacharts";
import NormalTextTooltipol from "../../components/NormalTextTooltipol.vue"; // 超出文字省略
import rightView1 from "./components/right1/right1";
import echarts from "echarts";
import {
  Huangcaihe,
  BeishaRiver,
  Dongfanghongmaincanal,
  Landongmaincanal,
  Lancaitrunkcanal,
  Lanqimaincanal,
  Landongmaincanal2,
  SimingRiver,
  Helihe,
  Zhangxinhe,
  Weidongmaincanal,
  Shangqiumaincanal,
  WestBranchofduzhuangRiver,
} from "../../assets/js/database";

export default {
  name: "dataV",
  data() {
    return {
      mesTitle: "全屏展示地图",
      imgBoxZQ: require("../../assets/Newicon/zhaqian/icon-waterline-0.png"),
      imgBoxZH: require("../../assets/Newicon/zhahou/after-icon-waterline-0.png"),
      imgKs: require("../../assets/Newicon/dam-icon/dam-icon-2.png"),
      ssllImg: require("../../assets/Newicon/liuliang.png"),
      ljllImg: require("../../assets/Newicon/leijiflow.png"),
      pickerOptions: {
        //大于当前月分的日期不可选
        disabledDate: (time) => {
          var date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          if (month >= 1 && month <= 9) {
            month = "0" + month;
          }
          var currentdate = year.toString() + month.toString();

          var timeyear = time.getFullYear();
          var timemonth = time.getMonth() + 1;
          if (timemonth >= 1 && timemonth <= 9) {
            timemonth = "0" + timemonth;
          }
          var timedate = timeyear.toString() + timemonth.toString();
          return currentdate < timedate;
        },
      },
      center: {
        lng: 114.982763,
        lat: 34.883771,
      },
      mapStyle: {
        styleJson: [
          {
            featureType: "city",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "town",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "city",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "district",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "village",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "island",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "boundary",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "country",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "background",
            elementType: "geometry",
            stylers: {
              color: "#001a34ff",
            },
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: {
              color: "#ffffffff",
              weight: "60",
            },
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "water",
            elementType: "labels.text",
            stylers: {
              fontsize: "26",
            },
          },
          {
            featureType: "districtlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#5a8abdff",
              weight: "50",
            },
          },
          {
            featureType: "districtlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
              weight: "0",
            },
          },
          {
            featureType: "poilabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#00cdffff",
            },
          },
          {
            featureType: "poilabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
              weight: "0",
            },
          },
          {
            featureType: "districtlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: "28",
            },
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: {
              color: "#088fe9ff",
            },
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
              weight: "0.6",
            },
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: {
              color: "#b1fcffff",
              weight: "50",
            },
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "road",
            elementType: "labels.text",
            stylers: {
              fontsize: "24",
            },
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: {
              color: "#023363ff",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffecbaff",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#00bcecff",
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#000000ff",
              weight: "60",
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "districtlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "village",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highway",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "railway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "airportlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "airportlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "lifeservicelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "lifeservicelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "carservicelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "carservicelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "financelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "financelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "INTERNATIONALIZEICONICON",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "INTERNATIONALIZEICONICON",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "nationalway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "nationalway",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "provincialway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "provincialway",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "cityhighway",
            elementType: "geometry",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "cityhighway",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "nationalwaysign",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "nationalwaysign",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "provincialwaysign",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "provincialwaysign",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "tertiarywaysign",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "subwaylabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "subwaylabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "roadarrow",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "footbridge",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "crosswalk",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "underpass",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "parkingspace",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "laneline",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "tertiarywaysign",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highwaysign",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highwaysign",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
        ],
      },
      zoom: 12,
      showSiteVos: true,
      labelStyle1: {
        padding: "4px 5px",
        cursor: "pointer",
        zIndex: "20",
        border: "1px solid #007ea6",
        color: "#fff",
        background: "rgba(2, 67, 98, 0.8)",
      },
      labelStyle3: {
        padding: "2px 4px 4px 2px",
        cursor: "pointer",
        zIndex: "20",
        border: "1px solid #007ea6",
        color: "#fff",
        background: "rgba(2 67 98 / 50%)",
      },
      labelStyle4: {
        padding: "2px 4px 4px 2px",
        cursor: "pointer",
        zIndex: "20",
        border: "1px solid #007ea6",
        color: "#fff",
        background: "rgba(2 67 98 / 50%)",
      },
      titleNum: [],
      ditchFlowMeters: [], // 渠道流量计
      flowMeters: [], // 流量计
      pumpStations: [], // 泵站
      siteVos: [], // 站点列表
      map: null,
      TXMap: null,
      district: null,
      chartData2: null,
      yearV: "", // 年
      montv: "",
      total: "",
      linemontv: "", // 线状图
      flowmontv: "", // 柱状图
      warningList: "", // 数据
      chartData3: null,
      chartData4: null,
      siteItemVo: "", // 站点数据
      Selectedvalue: "", // 节制闸实时信息站点
      stationBzList: "",
      policeValue: "",
      noDisposeLists: [], // 未处理的数据
      gateVo: "",
      currId: "", // 当前站点id
      gateVogateHeight: 0,
      zhadata: [],
      numList: 0,
      isMainsData: "",
      alarmStatusData: "",
      siteItemVoData: "",
      gateVoData: "",
      showFlase: true,
      waterPole: false, // 报警状态
      zoomMin: 12,
      Huangcaihe: Huangcaihe,
      BeishaRiver: BeishaRiver,
      Dongfanghongmaincanal: Dongfanghongmaincanal,
      Landongmaincanal: Landongmaincanal,
      Lancaitrunkcanal: Lancaitrunkcanal,
      Lanqimaincanal: Lanqimaincanal,
      Landongmaincanal2: Landongmaincanal2,
      SimingRiver: SimingRiver,
      Helihe: Helihe,
      Zhangxinhe: Zhangxinhe,
      Weidongmaincanal: Weidongmaincanal,
      Shangqiumaincanal: Shangqiumaincanal,
      WestBranchofduzhuangRiver: WestBranchofduzhuangRiver,
      nowDate: "",
      oneMonthsAgo: "",
    };
  },
  components: {
    headerDataV,
    bingEchart,
    NormalTextTooltipol,
    rightView1,
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3,
      };
    },
  },
  mounted() {
    this.getYMD();
    this.Gateopeningstatistics();
    this.lineSerch(); // 数位曲线图
    this.flowSerch(this.flowmontv);
    this.showDown();
    this.police(this.policeValue);
  },
  created() {
    this.curDate();
    this.getDataWater(); // 获取点位数据
  },
  methods: {
    // 全屏展示地图
    Showother() {
      this.showFlase = !this.showFlase;
      if (this.showFlase == false) {
        this.mesTitle = "退出全屏地图";
      } else {
        this.mesTitle = "全屏展示地图";
      }
    },
    // 打开信息窗口
    openInfoBox(lng, lat, id) {
      this.center.lng = lng;
      this.center.lat = lat;
      // 点击获取接口数据
      getSiteByIdList({ id: id }).then((res) => {
        this.isMainsData = res.data.isMains;
        this.alarmStatusData = res.data.alarmStatus;
        this.siteItemVoData = res.data.siteItemVo;
        this.gateVoData = res.data.gateVo;
        this.Acquisitiongate(this.siteItemVoData.id);
        this.showDownid(this.siteItemVoData.id);
        this.Selectedvalue = this.siteItemVoData.siteName;
        let html1 = `<div class="rain1" style="position: absolute;
    z-index: 9;">
    <div class="rainBoxOne">
      <div style="margin:0 12px">

        <div class="polyin"></div>

        <div class="polyin"></div>

      </div>
    </div>
  </div>`;
        // 闸门
        let html = `<div class="rain1" style=" z-index: 9;">
        <div class="rainBoxView">
            <div class="header-title">
                ${this.siteItemVoData.siteName}
            </div>
            <div class="zqsw">闸前水位： ${this.siteItemVoData.gateLevel}(cm)</div>
            <div class="zhsw">闸后水位：  ${this.siteItemVoData.currentLevel}(cm)</div>
            <div class="ssll">瞬时流量： ${this.siteItemVoData.traffic}(m³/h)</div>
            <div class="liusu">流速： ${this.siteItemVoData.velocity}(m/s)</div>
            <div class="detail">
             <div></div>
             <div class="siteDetail" id="detailClick">站点详情 ></div>
             </div>
        </div>
  </div>`;

        const opts = {
          boxStyle: {
            width: "310px",
            height: "230px",
          },
          closeIconMargin: "calc(30/1080*100vh) calc(30/1920*100vw) 0 0",
          closeIconUrl: require("../../assets/Newicon/lk-close.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        if (this.isMains == 1) {
          this.infoBox = new BMapLib.InfoBox(this.map, html1, opts);
        } else {
          this.infoBox = new BMapLib.InfoBox(this.map, html, opts);
        }
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        var btndetail = document.querySelector("#detailClick");
        // console.log(this.siteItemVoData.id)
        btndetail.onclick = () => {
          this.$router.push({
            path: `main/StationManage/${this.siteItemVoData.id}`,
          });
        };
      });
    },
    // 自定义经纬度高亮区域 根据自定义经纬度展示其余遮罩
    handler({ BMap, map }) {
      this.map = map;
      map.setMapStyleV2(this.mapStyle);
      this.getBoundary(this.map, "兰考县");
      var view = map.getViewport(eval(this.markers));
      // map.addEventListener("zoomend", function (e) {
      //   this.zoom = map.getZoom(); // 获取缩放级别
      // });
    },
    // 画边
    getBoundary(map, district) {
      var bdary = new BMap.Boundary();
      bdary.get(district, function (rs) {
        // 获取行政区域
        // map.clearOverlays(); //清除地图覆盖物
        var count = rs.boundaries.length; // 行政区域的点有多少个
        if (count === 0) {
          /* alert('未能获取当前输入行政区域'); */
          return;
        }
        const EN_JW = "180, 90;";
        const NW_JW = "-180,  90;";
        const WS_JW = "-180, -90;";
        const SE_JW = "180, -90;";
        // 东南西北四个角添加一个覆盖物
        const ply1 = new BMap.Polygon(
          rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
          {
            strokeColor: "none",
            // fillColor: "rgba(6,22,47,0.9)",
            fillColor: "none",
            // fillOpacity: 1,
            // strokeOpacity: 1,
          }
        );
        map.addOverlay(ply1);
        var pointArray = [];
        for (var i = 0; i < count; i++) {
          var ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 3, // 设置多边形边线线粗
            strokeOpacity: 1, // 设置多边形边线透明度0-1
            StrokeStyle: "solid", // 设置多边形边线样式为实线或虚线，取值 solid 或 dashed
            strokeColor: "rgba(0,60,255,0.2)", //  #033353设置多边形边线颜色
            // fillColor: "rgba(255,255,255)", // 设置多边形填充颜色
            fillOpacity: 0.01, // 设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下
          }); // 建立多边形覆盖物
          map.addOverlay(ply); // 添加覆盖物
        }
      });
    },
    // 点击地图
    getClickInfo(e) {
      // console.log("{" + 'lng:', e.point.lng + ',' + 'lat:' + e.point.lat + "},")
    },
    // 上一页面
    prevPage(val, list) {
      this.numList--;
      if (this.numList < 0) {
        this.numList = list.length - 1;
      }
      this.gateVo = list[this.numList];
      let numProcess = Number(list[this.numList].gateHeight); // 闸门高度
      let gateHeightLimit = Number(list[this.numList].gateHeightLimit); // 闸门限高
      let data = (numProcess / gateHeightLimit) * 100;
      let percentage = Math.round(data);
      if (percentage > 100) {
        this.gateVogateHeight = 100;
      } else {
        this.gateVogateHeight = percentage;
      }
    },
    // 下一页数据
    nextPage(val, list) {
      this.numList++;
      if (this.numList == list.length) {
        this.numList = 0;
      }
      this.gateVo = list[this.numList];

      let numProcess = Number(list[this.numList].gateHeight); // 闸门高度
      let gateHeightLimit = Number(list[this.numList].gateHeightLimit); // 闸门限高
      let data = (numProcess / gateHeightLimit) * 100;
      let percentage = Math.round(data);
      // this.gateVogateHeight = percentage
      if (percentage > 100) {
        this.gateVogateHeight = 100;
      } else {
        this.gateVogateHeight = percentage;
      }
    },
    // 点击详情进入详情
    detail(val) {
      this.$router.push(`/main/StationManage/${val.siteId}`);
    },
    //获取当前日期
    getYMD() {
      let myDate = new Date();
      let myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      let myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let myToday = myDate.getDate(); //获取当前日(1-31)
      myMonth = myMonth > 9 ? myMonth : "0" + myMonth;
      myToday = myToday > 9 ? myToday : "0" + myToday;
      this.nowDate = myYear + "-" + myMonth + "-" + myToday;
      this.GetPreMonthDay(this.nowDate, 1);
      // return this.nowDate;
    },
    // 获取1个月前的日期
    GetPreMonthDay(date, monthNum) {
      var dateArr = date.split("-");
      var year = dateArr[0]; //获取当前日期的年份
      var month = dateArr[1]; //获取当前日期的月份
      var day = dateArr[2]; //获取当前日期的日
      var days = new Date(year, month, 0);
      days = days.getDate(); //获取当前日期中月的天数
      var year2 = year;
      var month2 = parseInt(month) - monthNum;
      if (month2 <= 0) {
        var absM = Math.abs(month2);
        year2 =
          parseInt(year2) - Math.ceil(absM / 12 == 0 ? 1 : parseInt(absM) / 12);
        month2 = 12 - (absM % 12);
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
        day2 = days2;
      }
      if (month2 < 10) {
        month2 = "0" + month2;
      }
      var t2 = year2 + "-" + month2 + "-" + day2;
      this.oneMonthsAgo = t2;
      // return t2;
    },
    // 报警信息数据
    police(val) {
      let year = val.slice(0, 4);
      let month = val.slice(6, 10);
      if (val) {
        this.$get(`/alarmRecord/page/${1}/${9999}`, { year, month }).then(
          (res) => {
            let resdata = res.data.list;
            let waerData = [];
            // resdata.forEach((v) => {
            //   if (v.type == 1) {
            //     waerData.push(v);
            //   }
            // });
            this.noDisposeLists = resdata;
            this.tableDataList();
          }
        );
      } else {
        this.$get(`/alarmRecord/page/${1}/${9999}`, {
          startTime: this.oneMonthsAgo,
          endTime: this.nowDate,
        }).then((res) => {
          let resdata = res.data.list;
          let waerData = [];
          // resdata.forEach((v) => {
          //   if (v.type == 1) {
          //     waerData.push(v);
          //   }
          // });
          this.noDisposeLists = resdata;
          this.tableDataList();
        });
      }
    },
    // 报警类型转换
    tableDataList() {
      this.noDisposeLists.forEach((item) => {
        if (item.type == "1") {
          var arr = item.reason.split("超限");
          item.typeName = "水位报警超限" + arr[1];
        }
        if (item.type == "2") {
          item.typeName = "电量报警";
        }
        if (item.type == "3") {
          item.typeName = "设备报警";
        }
        if (item.type == "4") {
          item.typeName = "非法入侵报警";
        }
        if (item.type == "5") {
          item.typeName = "非法入侵报警";
        }
      });
    },
    // 下拉框选中后搜索
    SelectSerach() {
      this.numList = 0;
      this.showDownid(this.Selectedvalue);
      this.Acquisitiongate(this.Selectedvalue);
    },
    // 下拉框的数据获取
    showDown() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999,
      }).then((res) => {
        if (res.code == 200) {
          this.currId = res.data.list[0].id;
          this.stationBzList = res.data.list.map((item) => {
            return {
              value: item.id,
              label: item.siteName,
            };
          });
          this.Selectedvalue = this.stationBzList[0].value;
          //  根据id查询数据
          this.showDownid(this.stationBzList[0].value);
          this.Acquisitiongate(this.currId);
        }
      });
    },
    // 获取当前的闸门
    Acquisitiongate(val) {
      getSitesIdList({ id: val }).then((res) => {
        this.zhadata = res.data;
        let numProcess = Number(res.data[0].gateHeight); // 闸门高度
        let gateHeightLimit = Number(res.data[0].gateHeightLimit); // 闸门限高
        let data = (numProcess / gateHeightLimit) * 100;
        let percentage = Math.round(data);
        if (percentage > 100) {
          this.gateVogateHeight = 100;
        } else {
          this.gateVogateHeight = percentage;
        }
        // this.gateVogateHeight = percentage
        if (this.zhadata.length == 1) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-1.png");
        } else if (this.zhadata.length == 2) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-2.png");
        } else if (this.zhadata.length == 3) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-3.png");
        } else if (this.zhadata.length == 4) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-4.png");
        } else if (this.zhadata.length == 5) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-5.png");
        } else if (this.zhadata.length == 6) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-6.png");
        } else if (this.zhadata.length == 7) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-7.png");
        } else if (this.zhadata.length == 8) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-8.png");
        } else if (this.zhadata.length == 9) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-9.png");
        } else if (this.zhadata.length == 10) {
          this.imgKs = require("../../assets/Newicon/zhamen/dam-icon-10.png");
        }
      });
    },
    // 获取当前站点数据
    showDownid(val) {
      let params = {
        id: val,
      };
      this.$get(`/site/getSiteById`, params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.siteItemVo = res.data.siteItemVo;
            this.ssllImgIf(res.data.siteItemVo.traffic);
            this.ljllImgIf(res.data.siteItemVo.trafficSum);
            if (this.siteItemVo.gateLevel > this.siteItemVo.warningLevel) {
              // 闸前报警判断
              this.Pregatealarm();
              // this.imgBoxZQ = require('../../assets/Newicon/zhaqian/icon-waterline-alarm.png')
              this.waterPole = true;
            } else {
              this.waterPole = false;
              this.zhaqianWPanduan(); // 闸前水位判断
            }
            this.zhahousWPandun(); // 闸后水位判断
            this.gateVo = res.data.gateVo;
            // this.gateVogateHeight = Number(res.data.gateVo.gateHeight)
            let numProcess = Number(res.data.gateVo.gateHeight); // 闸门高度
            let gateHeightLimit = Number(res.data.gateVo.gateHeightLimit); // 闸门限高
            let data = (numProcess / gateHeightLimit) * 100;
            let percentage = Math.round(data);
            if (percentage > 100) {
              this.gateVogateHeight = 100;
            } else {
              this.gateVogateHeight = percentage;
            }
          }
        }
      });
    },
    // 闸前水位报警判断
    Pregatealarm() {
      this.imgBoxZQ = ""; // 每次清空闸前水位的图片
      if (this.siteItemVo.gateLevel == 0) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-0.png");
      }
      if (this.siteItemVo.gateLevel > 0 && this.siteItemVo.gateLevel < 25) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-1.png");
      } else if (
        this.siteItemVo.gateLevel > 25 &&
        this.siteItemVo.gateLevel < 50
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-2.png");
      } else if (
        this.siteItemVo.gateLevel > 50 &&
        this.siteItemVo.gateLevel < 75
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-3.png");
      } else if (
        this.siteItemVo.gateLevel > 75 &&
        this.siteItemVo.gateLevel < 100
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-4.png");
      } else if (
        this.siteItemVo.gateLevel > 100 &&
        this.siteItemVo.gateLevel < 125
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-5.png");
      } else if (
        this.siteItemVo.gateLevel > 125 &&
        this.siteItemVo.gateLevel < 165
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-7.png");
      } else if (
        this.siteItemVo.gateLevel > 145 &&
        this.siteItemVo.gateLevel < 175
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-8.png");
      } else if (
        this.siteItemVo.gateLevel > 175 &&
        this.siteItemVo.gateLevel < 200
      ) {
        this.imgBoxZQ = require("../../assets/images/benghaoalarm/after-icon-waterline-9.png");
      }
    },
    // 闸前水位判断
    zhaqianWPanduan() {
      if (this.siteItemVo.gateLevel == 0 && this.siteItemVo.gateLevel == 0) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-0.png");
      }
      if (this.siteItemVo.gateLevel > 0 && this.siteItemVo.gateLevel < 25) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-1.png");
      } else if (
        this.siteItemVo.gateLevel > 25 &&
        this.siteItemVo.gateLevel < 50
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-2.png");
      } else if (
        this.siteItemVo.gateLevel > 55 &&
        this.siteItemVo.gateLevel < 75
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-3.png");
      } else if (
        this.siteItemVo.gateLevel > 75 &&
        this.siteItemVo.gateLevel < 95
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-4.png");
      } else if (
        this.siteItemVo.gateLevel > 95 &&
        this.siteItemVo.gateLevel < 115
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-5.png");
      } else if (
        this.siteItemVo.gateLevel > 115 &&
        this.siteItemVo.gateLevel < 135
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-6.png");
      } else if (
        this.siteItemVo.gateLevel > 135 &&
        this.siteItemVo.gateLevel < 155
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-7.png");
      } else if (
        this.siteItemVo.gateLevel > 155 &&
        this.siteItemVo.gateLevel < 175
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-8.png");
      } else if (
        this.siteItemVo.gateLevel > 175 &&
        this.siteItemVo.gateLevel < 200
      ) {
        this.imgBoxZQ = require("../../assets/Newicon/zhaqian/icon-waterline-9.png");
      }
    },
    // 瞬时流量判断
    ssllImgIf(traffic) {
      if (
        (traffic == 0 && traffic < 100) ||
        (traffic == 0 && traffic < 1000) ||
        (traffic == 0 && traffic < 10000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-1.png");
      } else if (
        (traffic > 100 && traffic < 200) ||
        (traffic > 1000 && traffic < 2000) ||
        (traffic > 10000 && traffic < 20000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-2.png");
      } else if (
        (traffic > 200 && traffic < 300) ||
        (traffic > 2000 && traffic < 3000) ||
        (traffic > 20000 && traffic < 30000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-3.png");
      } else if (
        (traffic > 300 && traffic < 400) ||
        (traffic > 3000 && traffic < 4000) ||
        (traffic > 30000 && traffic < 40000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-4.png");
      } else if (
        (traffic > 400 && traffic < 500) ||
        (traffic > 4000 && traffic < 5000) ||
        (traffic > 40000 && traffic < 50000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-5.png");
      } else if (
        (traffic > 500 && traffic < 600) ||
        (traffic > 5000 && traffic < 6000) ||
        (traffic > 50000 && traffic < 60000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-6.png");
      } else if (
        (traffic > 600 && traffic < 700) ||
        (traffic > 6000 && traffic < 7000) ||
        (traffic > 60000 && traffic < 70000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-7.png");
      } else if (
        (traffic > 700 && traffic < 800) ||
        (traffic > 7000 && traffic < 8000) ||
        (traffic > 70000 && traffic < 80000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-8.png");
      } else if (
        (traffic > 800 && traffic < 900) ||
        (traffic > 8000 && traffic < 9000) ||
        (traffic > 80000 && traffic < 90000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-9.png");
      } else if (
        (traffic > 900 && traffic < 1000) ||
        (traffic > 9000 && traffic < 10000) ||
        (traffic > 90000 && traffic < 100000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-10.png");
      } else if (
        (traffic > 1100 && traffic < 1200) ||
        (traffic > 11000 && traffic < 12000) ||
        (traffic > 110000 && traffic < 1200000)
      ) {
        this.ssllImg = require("../../assets/Newicon/flow-icon2/dashboard-11.png");
      }
      // else if (traffic > 1200 && traffic < 1300 || traffic > 12000 && traffic < 13000 || traffic > 120000 && traffic < 1300000) {
      //   this.ssllImg = require('../../assets/Newicon/flow-icon2/dashboard-12.png')
      // } else if (traffic > 1300 && traffic < 1400 || traffic > 13000 && traffic < 14000 || traffic > 130000 && traffic < 1400000) {
      //   this.ssllImg = require('../../assets/Newicon/flow-icon2/dashboard-1.png')
      // }
    },
    // 累计流量判断
    ljllImgIf(trafficSum) {
      if (
        (trafficSum == 0 && trafficSum < 100) ||
        (trafficSum == 0 && trafficSum < 1000) ||
        (trafficSum == 0 && trafficSum < 10000) ||
        (trafficSum == 0 && trafficSum < 100000) ||
        (trafficSum == 0 && trafficSum < 1000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-01.png");
      } else if (
        (trafficSum > 100 && trafficSum < 200) ||
        (trafficSum > 1000 && trafficSum < 2000) ||
        (trafficSum > 10000 && trafficSum < 20000) ||
        (trafficSum > 100000 && trafficSum < 200000) ||
        (trafficSum > 1000000 && trafficSum < 2000000) ||
        (trafficSum > 10000000 && trafficSum < 20000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-02.png");
      } else if (
        (trafficSum > 200 && trafficSum < 300) ||
        (trafficSum > 2000 && trafficSum < 3000) ||
        (trafficSum > 20000 && trafficSum < 30000) ||
        (trafficSum > 200000 && trafficSum < 300000) ||
        (trafficSum > 2000000 && trafficSum < 3000000) ||
        (trafficSum > 20000000 && trafficSum < 30000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-03.png");
      } else if (
        (trafficSum > 300 && trafficSum < 400) ||
        (trafficSum > 3000 && trafficSum < 4000) ||
        (trafficSum > 30000 && trafficSum < 40000) ||
        (trafficSum > 300000 && trafficSum < 400000) ||
        (trafficSum > 3000000 && trafficSum < 4000000) ||
        (trafficSum > 30000000 && trafficSum < 40000000) ||
        (trafficSum > 300000000 && trafficSum < 400000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-04.png");
      } else if (
        (trafficSum > 400 && trafficSum < 500) ||
        (trafficSum > 4000 && trafficSum < 5000) ||
        (trafficSum > 40000 && trafficSum < 50000) ||
        (trafficSum > 400000 && trafficSum < 500000) ||
        (trafficSum > 4000000 && trafficSum < 5000000) ||
        (trafficSum > 40000000 && trafficSum < 50000000) ||
        (trafficSum > 400000000 && trafficSum < 500000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-05.png");
      } else if (
        (trafficSum > 500 && trafficSum < 600) ||
        (trafficSum > 5000 && trafficSum < 6000) ||
        (trafficSum > 50000 && trafficSum < 60000) ||
        (trafficSum > 500000 && trafficSum < 600000) ||
        (trafficSum > 5000000 && trafficSum < 6000000) ||
        (trafficSum > 50000000 && trafficSum < 60000000) ||
        (trafficSum > 500000000 && trafficSum < 600000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-06.png");
      } else if (
        (trafficSum > 600 && trafficSum < 700) ||
        (trafficSum > 6000 && trafficSum < 7000) ||
        (trafficSum > 60000 && trafficSum < 70000) ||
        (trafficSum > 600000 && trafficSum < 700000) ||
        (trafficSum > 6000000 && trafficSum < 7000000) ||
        (trafficSum > 60000000 && trafficSum < 70000000) ||
        (trafficSum > 600000000 && trafficSum < 700000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-07.png");
      } else if (
        (trafficSum > 700 && trafficSum < 800) ||
        (trafficSum > 7000 && trafficSum < 8000) ||
        (trafficSum > 70000 && trafficSum < 80000) ||
        (trafficSum > 700000 && trafficSum < 800000) ||
        (trafficSum > 7000000 && trafficSum < 8000000) ||
        (trafficSum > 70000000 && trafficSum < 80000000) ||
        (trafficSum > 700000000 && trafficSum < 800000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-08.png");
      } else if (
        (trafficSum > 800 && trafficSum < 900) ||
        (trafficSum > 8000 && trafficSum < 9000) ||
        (trafficSum > 80000 && trafficSum < 90000) ||
        (trafficSum > 800000 && trafficSum < 900000) ||
        (trafficSum > 8000000 && trafficSum < 9000000) ||
        (trafficSum > 80000000 && trafficSum < 90000000) ||
        (trafficSum > 800000000 && trafficSum < 900000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-09.png");
      } else if (
        (trafficSum > 900 && trafficSum < 1000) ||
        (trafficSum > 9000 && trafficSum < 10000) ||
        (trafficSum > 90000 && trafficSum < 100000) ||
        (trafficSum > 900000 && trafficSum < 1000000) ||
        (trafficSum > 9000000 && trafficSum < 10000000) ||
        (trafficSum > 90000000 && trafficSum < 100000000) ||
        (trafficSum > 900000000 && trafficSum < 1000000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-10.png");
      } else if (
        (trafficSum > 1100 && trafficSum < 1200) ||
        (trafficSum > 11000 && trafficSum < 12000) ||
        (trafficSum > 110000 && trafficSum < 1200000) ||
        (trafficSum > 1100000 && trafficSum < 12000000) ||
        (trafficSum > 11000000 && trafficSum < 120000000) ||
        (trafficSum > 110000000 && trafficSum < 1200000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-11.png");
      } else if (
        (trafficSum > 1200 && trafficSum < 1300) ||
        (trafficSum > 12000 && trafficSum < 13000) ||
        (trafficSum > 120000 && trafficSum < 1300000) ||
        (trafficSum > 1200000 && trafficSum < 13000000) ||
        (trafficSum > 12000000 && trafficSum < 130000000) ||
        (trafficSum > 120000000 && trafficSum < 1300000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-12.png");
      } else if (
        (trafficSum > 1300 && trafficSum < 1400) ||
        (trafficSum > 13000 && trafficSum < 14000) ||
        (trafficSum > 130000 && trafficSum < 1400000) ||
        (trafficSum > 1300000 && trafficSum < 14000000) ||
        (trafficSum > 13000000 && trafficSum < 140000000) ||
        (trafficSum > 130000000 && trafficSum < 1400000000)
      ) {
        this.ljllImg = require("../../assets/Newicon/flow-icon/flow-icon-13.png");
      }
    },
    // 闸后水位判断
    zhahousWPandun() {
      if (
        this.siteItemVo.currentLevel == 0 &&
        this.siteItemVo.currentLevel == 0
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-0.png");
      }
      if (
        this.siteItemVo.currentLevel > 0 &&
        this.siteItemVo.currentLevel < 25
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-1.png");
      } else if (
        this.siteItemVo.currentLevel > 25 &&
        this.siteItemVo.currentLevel < 50
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-2.png");
      } else if (
        this.siteItemVo.currentLevel > 55 &&
        this.siteItemVo.currentLevel < 75
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-3.png");
      } else if (
        this.siteItemVo.currentLevel > 75 &&
        this.siteItemVo.currentLevel < 95
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-4.png");
      } else if (
        this.siteItemVo.currentLevel > 95 &&
        this.siteItemVo.currentLevel < 115
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-5.png");
      } else if (
        this.siteItemVo.currentLevel > 115 &&
        this.siteItemVo.currentLevel < 135
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-6.png");
      } else if (
        this.siteItemVo.currentLevel > 135 &&
        this.siteItemVo.currentLevel < 155
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-7.png");
      } else if (
        this.siteItemVo.currentLevel > 155 &&
        this.siteItemVo.currentLevel < 175
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-8.png");
      } else if (
        this.siteItemVo.currentLevel > 175 &&
        this.siteItemVo.currentLevel < 200
      ) {
        this.imgBoxZH = require("../../assets/Newicon/zhahou/after-icon-waterline-9.png");
      }
    },
    // 获取流量柱状图数据
    flowSerch(val) {
      var year = val.slice(0, 4);
      var month = val.slice(5, 10);
      let chartData4 = null;
      Flowinterface({ year, month }).then((res) => {
        if (res.code == 200) {
          let chartData4 = this.Histogram();
          let flowdata = res.data;
          let X = [];
          let traffic = [];
          flowdata.forEach((v) => {
            X.push(v.siteName);
            traffic.push((v.traffic / 1000).toFixed(2));
          });
          chartData4.xAxis[0].data = X;
          chartData4.series[0].data = traffic;
          this.chartData4 = chartData4;
        }
      });
    },
    // 实时水位曲线图
    lineSerch(val) {
      let year = this.linemontv.slice(0, 4);
      let month = this.linemontv.slice(6, 10);
      let chartData3 = null;
      Waterleveldata({ year, month }).then((res) => {
        if (res.code == 200) {
          let WaterData = res.data;
          chartData3 = this.zheline();
          let X = [];
          let gateLevel = [];
          let level = [];
          WaterData.forEach((v) => {
            X.push(v.siteName);
            gateLevel.push(v.gateLevel);
            level.push(v.level);
          });
          chartData3.xAxis[0].data = X;
          chartData3.series[0].data = gateLevel; // 	闸前水位
          chartData3.series[1].data = level; // 	闸后水位
          this.chartData3 = chartData3;
        }
      });
    },
    // 生成当前月数
    curDate() {
      var datetime = new Date();
      var year = datetime.getFullYear();
      var month =
        datetime.getMonth() + 1 < 10
          ? "0" + (datetime.getMonth() + 1)
          : datetime.getMonth() + 1;
      var date =
        datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      this.montv = year + "-" + month;
      // this.linemontv = year + "-" + month
      // this.flowmontv = year + "-" + month
      // this.policeValue= year + "-" + month // 报警信息查询
    },
    // 闸门开启统计
    Gateopeningstatistics() {
      let chartData2 = null;
      var year = this.montv.slice(0, 4);
      var month = this.montv.slice(5, 10);
      Turnonstatistics({ year, month }).then((res) => {
        if (res.code == 200) {
          this.total = res.data.count;
          var tongjiData = res.data.gateUpRecordVos;
          chartData2 = this.bingLine();

          tongjiData.forEach((v, i) => {
            if (v.count != 0) {
              let dataList = {
                name: v.siteName,
                value: v.count,
              };
              chartData2.series[0].data.push(dataList);
            }
          });
          this.chartData2 = chartData2;
        }
      });
    },
    // 节制闸开启统计饼图
    bingLine() {
      return {
        tooltip: {
          trigger: "item",
          backgroundColor: "#045094",
          borderWidth: 0, // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 14,
          },
          // formatter: "{a} <br/>{b} :  {d}%",
          formatter: "{a} <br/> {b} : {c} ({d}%)",
        },
        // legend: {
        //   top: "1%",
        //   left: "center",
        //   textStyle: {
        //     color: "#fff",
        //     fontSize: 14,
        //   },
        // },
        series: [
          {
            name: "开启次数",
            type: "pie",
            radius: [50, 85], // 调整饼状图大小
            // center: ["50%", "30%"],
            // roseType: "area",
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: "#fff",
              borderRadius: 5,
              normal: {
                // 自定义颜色
                color: function (colors) {
                  var colorList = [
                    "#62D9AD",
                    "#5AAEF3",
                    "#D4EC59",
                    "#23C2DB",
                    "#6D9AE7",
                    "#4A5EE2",
                    "#4A6FE2",
                    "#a8dffa",
                    "#E65A56",
                    "#FF974C",
                    "#FFDC4C",
                    "#5B6E96",
                    "#A8DFFA",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
            // 提示线
            label: {
              show: true,
              position: "outside",
              normal: {
                textStyle: {
                  color: "#fff",
                  fontSize: "13",
                },
              },
            },
            //指示文字线
            labelLine: {
              show: true,
              length: 13, //调整上下位置
            },
            data: [
              // {value: 30, name: "rose 1"},
              // {value: 28, name: "rose 2"},
              // {value: 26, name: "rose 3"},
              // {value: 24, name: "rose 4"},
              // {value: 24, name: "rose 4"},
            ],

            center: ["50%", "50%"],
          },
        ],
      };
    },
    // 根据年月搜索当前的闸门开启统计
    curSerch(mount) {
      let chartData2 = null;
      var year = mount.slice(0, 4);
      var month = mount.slice(5, 10);
      Turnonstatistics({ year, month }).then((res) => {
        if (res.code == 200) {
          this.total = res.data.count;
          console.log(res);
          var tongjiData = res.data.gateUpRecordVos;
          chartData2 = this.bingLine();

          tongjiData.forEach((v, i) => {
            if (v.count != 0) {
              let dataList = {
                name: v.siteName,
                value: v.count,
              };
              chartData2.series[0].data.push(dataList);
            } else {
              return;
            }
          });
          this.chartData2 = chartData2;
        }
      });
    },
    // 前往站点详情
    goPush(id) {
      this.$router.push({ path: `/main/StationManage/${id}` });
    },
    // 移出隐藏
    closeInfoBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
        document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
        document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
      }
    },
    // 获取数据
    getDataWater() {
      getAllSiteList().then((res) => {
        this.siteVos = res.data.siteVos;
        this.siteVos.forEach((v) => {
          if (v.siteName == "北沙河龙庄节制闸") {
          }
        });
        // console.log('this.siteVos===>',this.siteVos)
        this.rainVos = res.data.rainVos;
        this.ditchFlowMeters = res.data.ditchFlowMeters.map((item) => {
          return {
            ...item,
            lng: item.lon,
          };
        });
        this.ditchFlowMeterCount = res.data.ditchFlowMeterCount;
        this.flowMeters = res.data.flowMeters.map((item) => {
          return {
            ...item,
            lng: item.lon,
          };
        });
        this.flowMeterCount = res.data.flowMeterCount;
        this.pumpStations = res.data.pumpStations.map((item) => {
          return {
            ...item,
            lng: item.lon,
          };
        });
        this.pumpStationCount = res.data.pumpStationCount;
        this.normalRainCount = res.data.normalRainCount;
        this.normalSiteCount = res.data.normalSiteCount;
        this.offlineRainCount = res.data.offlineRainCount;
        this.offlineSiteCount = res.data.offlineSiteCount;
        this.warningRainCount = res.data.warningRainCount;
        this.warningSiteCount = res.data.warningSiteCount;
      });
    },
    // 折线图
    zheline() {
      return {
        tooltip: {
          trigger: "axis",
          // position: function (point, params, dom, rect, size) {
          //   return [point[0] - 100, '20%']  //返回x、y（横向、纵向）两个点的位置
          // },
          position: function (point, params, dom, rect, size) {
            return [point[1], 0];
          },
          formatter: (params) => {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "：" +
                params[i].value +
                "cm";
            }
            return relVal;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "20%",
          top: "18%",
          containLabel: true,
        },
        dataZoom: [
          {
            width: "15",
            type: "slider",
            show: false, //flase直接隐藏图形
            xAxisIndex: [0],
            left: 33, //滚动条靠左侧的百分比
            bottom: 40,
            height: 20, //组件高度
            start: 0, //滚动条的起始位置
            showDataShadow: false, //是否显示数据阴影
            showDetail: false, //是否显示想洗数值信息
            end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
          {
            type: "inside",
            show: true,
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: 0.25, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              rotate: 60,
              interval: -1,
              textStyle: {
                color: "#fff",
              },
              // padding: [0, 0, 0, 50]    // 四个数字分别为上右下左与原位置距离
            },
            axisLine: {
              //x轴线的颜色以及宽度
              show: true,
              lineStyle: {
                color: "#fff",
                width: 0,
                type: "solid",
              },
            },
            splitLine: {
              //分割线配置
              show: false,
              lineStyle: {
                color: "rgba(219,225,255,1)",
              },
            },
          },
        ],
        yAxis: {
          type: "value",
          name: "cm（厘米）",
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
            padding: [0, 0, 0, 12],
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#015283",
            },
          },
        },
        series: [
          {
            name: "闸前水位",
            type: "line",
            // stack: 'Total',
            symbolSize: 5, //小圆点的大小
            data: [120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              normal: {
                color: "#ff6e76",
              },
            },
          },
          {
            name: "闸后水位",
            type: "line",
            // stack: 'Total',
            symbolSize: 5, //小圆点的大小
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              normal: {
                color: "#00d7e9",
              },
            },
          },
        ],
      };
    },
    //  堆叠渐变图
    Gradient() {
      return {
        color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
        tooltip: {
          trigger: "axis",
          position: function (point, params, dom, rect, size) {
            return [point[0] - 100, "20%"]; //返回x、y（横向、纵向）两个点的位置
          },
          formatter: (params) => {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "：" +
                params[i].value +
                "cm";
            }
            return relVal;
          },
        },
        toolbox: {
          feature: {},
        },
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            width: "15",
            type: "slider",
            show: false, //flase直接隐藏图形
            xAxisIndex: [0],
            left: 33, //滚动条靠左侧的百分比
            bottom: 40,
            height: 20, //组件高度
            start: 0, //滚动条的起始位置
            showDataShadow: false, //是否显示数据阴影
            showDetail: false, //是否显示想洗数值信息
            end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
          {
            type: "inside",
            show: true,
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: 0.25, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              rotate: 60,
              interval: -1,
              textStyle: {
                color: "#fff",
              },
              padding: [0, 0, 0, 50], // 四个数字分别为上右下左与原位置距离
            },
            axisLine: {
              //x轴线的颜色以及宽度
              show: true,
              lineStyle: {
                color: "#fff",
                width: 0,
                type: "solid",
              },
            },
            splitLine: {
              //分割线配置
              show: false,
              lineStyle: {
                color: "rgba(219,225,255,1)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "cm（厘米）",
            nameTextStyle: {
              color: "#fff",
              nameLocation: "start",
              padding: [0, 0, 0, 12],
            },
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#015283",
              },
            },
          },
        ],
        series: [
          {
            name: "闸前水位",
            type: "line",
            // stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: " #014efd  ",
                },
                {
                  offset: 1,
                  color: "#00c6fb",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: [140],
          },
          {
            name: "闸后水位",
            type: "line",
            // stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#43e97b",
                },
                {
                  offset: 1,
                  color: " #38f9d7",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: [120, 282, 111, 234, 220, 340, 310],
          },
        ],
      };
    },
    // 流量柱状图
    Histogram() {
      return {
        tooltip: {
          trigger: "axis",
          // position: function (point, params, dom, rect, size) {
          //   return [point[0] - 100, '10%']  //返回x、y（横向、纵向）两个点的位置
          // },
          position: function (point, params, dom, rect, size) {
            return [point[1], 0];
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              relVal +=
                "<br/>" + params[i].seriesName + "：" + params[i].value + "m³";
            }
            return relVal;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            width: "15",
            type: "slider",
            show: false, //flase直接隐藏图形
            xAxisIndex: [0],
            left: 33, //滚动条靠左侧的百分比
            bottom: 40,
            height: 20, //组件高度
            start: 0, //滚动条的起始位置
            showDataShadow: false, //是否显示数据阴影
            showDetail: false, //是否显示想洗数值信息
            end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
          {
            type: "inside",
            show: true,
            xAxisIndex: [0],
            start: 0, //滚动条的起始位置
            end: 0.25, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
        ],
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              rotate: 60,
              textStyle: {
                color: "#fff",
              },
              padding: [0, 0, 0, 30], // 四个数字分别为上右下左与原位置距离
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "m³（立方米）",
            nameTextStyle: {
              color: "#fff",
              nameLocation: "start",
              padding: [0, 0, 0, 35],
            },
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#015283",
              },
            },
          },
        ],
        series: [
          {
            name: "流量",
            type: "bar",
            barWidth: "60%",
            data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(3, 139, 187, .7)", // 0% 处的颜色
                    },
                    {
                      offset: 0.2,
                      color: "rgba(3, 139, 187, .7)", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 255, 246, 1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      };
    },
  },
};
</script>
<style lang="less">
body {
  //background-color: red !important;

  ::v-deep .el-picker-panel {
    background-color: red !important;
  }
}
</style>

<style lang="less" scoped>
@import "./dataViewData.less";
@import "./TopWindow.less";
@import "./Adaptation.less";

.waterPoleClass {
  color: red;
}

.disappearClass {
  right: 2.7% !important;
}

.disappeartrue {
  right: 25% !important;
}

.identification {
  right: 2.7% !important;
}

.identificationTrue {
  right: 25% !important;
}

.left_warp {
  position: relative;
}

* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}

input {
  -webkit-user-select: auto; /*webkit浏览器*/
}

textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}
</style>

