<template>
  <div class="right1">
    <div class="header_anfang">
      <div class="anfnagStatisticsBox">
        <img class="anfangimgbox" src="../../../../assets/Newicon/flowicon.png" alt="">
        <span class="spanPoli">安防预警统计</span>

      </div>
      <div class="afHistoricalquery">
        <div class="anftextsapn">历史数据查询</div>
        <div class="afclassMont">
          <el-date-picker
            v-model="Securitymontv"
            @change="SecuritySerch(Securitymontv)"
            type="month"
            placeholder="选择日期"
            value-format="yyyy-MM"
            :picker-options="pickerOptions"
            popper-class="curSerchMount"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="anfangchartsBox">
      <div class="echartsBoxAf">
        <div class="wchartBing" style="width: 100%;height: 54%;">
          <bingEchart class="warnClass" :option="chartDatawarn" style="width:100%;height:190%"></bingEchart>
          <div class="totallength">
            <div class="imgBgc">
              <img src="../../../../assets/Newicon/tongzh.png" alt="">
            </div>
            <span>总报警次数</span>
            <div class="poliClass">{{ poliTotal }}</div>
          </div>
        </div>
      </div>
      <div class="dateList">
        <div class="listBox">
          <div class="listData" v-for="(item,index) in SecurityData" :key="index">
            <div v-show="curpage == 1" class="Ranking">Top{{ index + 1 }}</div>
            <NormalTextTooltipol class="siteWarin" :text="item.siteName"></NormalTextTooltipol>
            <div class="frequency">{{ item.count }}次</div>
          </div>

        </div>
        <div class="precView">
          <el-pagination
            small
            :background="false"
            layout="prev, pager, next"
            :total="totalList"
            :current-page="this.page"
            :page-size="this.size"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SecurityBingLing, SecurityPage} from "../../../../api";
import bingEchart from "../../../../components/Echart/bingEacharts";
import NormalTextTooltipol from "../../../../components/NormalTextTooltipol";

export default {
  name: "right1",
  data() {
    return {
      Securitymontv: "", // 安防预警
      pickerOptions: { //大于当前月分的日期不可选
        disabledDate: (time) => {
          var date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          if (month >= 1 && month <= 9) {
            month = "0" + month;
          }
          var currentdate = year.toString() + month.toString();

          var timeyear = time.getFullYear();
          var timemonth = time.getMonth() + 1;
          if (timemonth >= 1 && timemonth <= 9) {
            timemonth = "0" + timemonth;
          }
          var timedate = timeyear.toString() + timemonth.toString();
          return currentdate < timedate;
        }
      },
      chartDatawarn: null,// 安防预警统计饼状图
      SecurityData: "", // 安防预警统计列表数据
      page: 1,
      size: 5,
      totalList: 0,
      curpage: 0, // 当前页
      poliTotal: 0,
    }
  },
  created() {
    this.curDate()
  },
  mounted() {
    this.SecuritySerch(this.Securitymontv)
  },
  components: {
    NormalTextTooltipol,
    bingEchart
  },
  methods: {
    curDate() {
      var datetime = new Date();
      var year = datetime.getFullYear();
      var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
      var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      this.Securitymontv = year + "-" + month
    },
    // 切换分页
    handleCurrentChange(val) {
      this.curpage = val
      var year = this.Securitymontv.slice(0, 4)
      var month = this.Securitymontv.slice(5, 10)
      let size = this.size
      let page = val
      SecurityPage({year, month, page, size}).then((res) => {
        if (res.code == 200) {
          this.SecurityData = res.data.list
          this.totalList = res.data.total
        }
      })
    },
    // 安防预警统计饼状图
    SecuritySerch(val) {
      var year = val.slice(0, 4)
      var month = val.slice(5, 10)
      let chartDatawarn = null
      SecurityBingLing({year, month}).then((res) => {
        if (res.code == 200) {
          var warningData = res.data
          this.warningList = res.data
          this.jsTotalLength(warningData)
          chartDatawarn = this.earlywarning();
          warningData.forEach((v, i) => {
            let dataList = {
              name: v.siteName,
              value: v.count,
            }
            chartDatawarn.series[0].data.push(dataList)
          })
          this.chartDatawarn = chartDatawarn;
        }
      })
      let page = this.page
      let size = this.size
      SecurityPage({year, month, page, size}).then((res) => {
        if (res.code == 200) {
          this.SecurityData = res.data.list
          this.totalList = res.data.total
        }
      })
    },
    // 计算报警次数
    jsTotalLength(data) {
      data.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        this.poliTotal += item.count;
      })
    },
    // 安防预警饼图
    earlywarning() {
      return {
        tooltip: {
          trigger: "item",
          backgroundColor: "#045094",
          borderWidth: 0, // 提示框浮层的边框宽。
          position: function (point, params, dom, rect, size) {
            return {left: '40%', bottom: 20};
          },
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 14,
          },
          // formatter: "{a} <br/>{b} :  {d}%",
          formatter: "{a} <br/> {b} : {c} <br/> 占比： ({d}%)"
        },
        // legend: {
        //   top: "1%",
        //   left: "center",
        //   textStyle: {
        //     color: "#fff",
        //     fontSize: 14,
        //   },
        // },
        series: [
          {
            name: "报警次数",
            type: "pie",
            radius: [45, 75], // 调整饼状图大小
            center: ["50%", "40%"],
            // center: ["50%", "30%"],
            // roseType: "area",
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: '#fff',
              borderRadius: 5,
              normal: {
                label: {
                  show: false   //隐藏标示文字
                },
                labelLine: {
                  show: false   //隐藏标示线
                },
                // 自定义颜色
                color: function (params) {
                  var colorList = [
                    // '#FF405B',
                    // '#AB47BC',
                    // '#7E57C2',
                    // '#5C6BC0',
                    // '#42A5F5',
                    // '#29B6F6',
                    // "#26C6DA",
                    // "#26A69A",
                    // "#66BB6A",
                    // "#9CCC65",
                    // "#FFEE58",
                    // '#00CEF1'
                    "#ff405b",
                    "#ab47bc",
                    "#7e57c2",
                    "#5c6bc0",
                    "#42a5f5",
                    "#29b6f6",
                    "#26c6da",
                    "#26a69a",
                    "#66bb6a",
                    "#9ccc65",
                    "#d4e157",
                    "#ffee58",
                  ];
                  return colorList[params.dataIndex];
                }
              },
            },
            // 提示线
            label: {
              show: false,
              position: "outside",
              normal: {
                textStyle: {
                  color: "#fff",
                  fontSize: "13",
                },
              },
            },
            //指示文字线
            labelLine: {
              show: true,
              length: 3, //调整上下位置
            },
            data: [
              // {value: 30, name: "rose 1"},
              // {value: 28, name: "rose 2"},
              // {value: 26, name: "rose 3"},
              // {value: 24, name: "rose 4"},
              // {value: 24, name: "rose 4"},
            ],


          },

        ],
      };
    },
  }
}
</script>

<style lang="less" scoped>
.right1 {
  width: 440px;
  height: 270px;
  background: url("../../../../assets/Newicon/bg@2x.png") no-repeat;
  background-size: 440px 270px;
  z-index: 999;
  color: #FFFFFF;
  font-size: 16px;
  overflow: hidden;

  .header_anfang {
    width: 100%;
    display: flex;
    margin-top: 10px;
    height: 40px;
    justify-content: space-between;
    align-items: center;

    .anfnagStatisticsBox {
      width: 240px;
      height: 40px;
      margin-left: 4px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('../../../../assets/Newicon/bg-title@2x.jpg') no-repeat;
      background-size: 100%;
      background-position: 50% 50%;

      .spanPoli {
        padding-right: 17px;
      }

      .anfangimgbox {
        width: 18px;
        height: 18px;
        padding-right: 7px;
      }
    }

    .afHistoricalquery {
      width: 380px;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .anftextsapn {
        width: 100px;
        line-height: 40px;
        //padding-left: 20px;
        font-size: 14px;
        color: #00CDFF;
      }

      .classYear {
        width: 75px;
        height: 40px;

        /deep/ .el-date-editor {
          width: 60px;
          //margin-left: 10px;
          .el-input__inner {
            padding: 5px 10px;
            border: 1px solid #038ABB;
            height: 25px !important;
            background: rgba(255, 255, 255, .1);
            color: #FFFFFF;
            font-size: 13px;
          }

          .el-input__icon {
            display: none !important;
          }
        }
      }

      .afclassMont {
        width: 130px;
        height: 40px;

        /deep/ .el-date-editor {
          width: 130px;

          .el-input__inner {
            padding: 5px 10px;
            border: 1px solid #038ABB;
            height: 25px !important;
            background: rgba(255, 255, 255, .1);
            color: #00CDFF;
            font-size: 13px;
          }

          .el-input__icon {
            display: none !important;
          }
        }
      }
    }
  }

  .anfangchartsBox {
    width: 97%;
    margin: 0 auto;
    height: 215px;
    display: flex;
    z-index: 99;

    .echartsBoxAf {
      width: 100%;
      height: 100%;

      .warnClass {
        background: url("../../../../assets/Newicon/flootepng.jpg") no-repeat;
        background-size: 70% 40%;
        background-position: 50% 90%;
      }

      .wchartBing {
        position: relative;
        //top: 16px;

        .totallength {
          width: 40.5%;
          height: 75%;
          top: 75.5%;
          right: 9.5%;
          transform: translate(-50%, -50%);
          position: absolute;
          border-radius: 50px;
          text-align: center;

          .imgBgc {
            width: 20px;
            height: 20px;
            margin: 0 auto;
            margin-top: 10px;

            img {
              width: 20px;
              height: 20px;
            }
          }

          .poliClass {
            font-size: 20px;
            color: #00CDFF;
            font-weight: bold;
          }
        }
      }
    }

    .dateList {
      width: 100%;
      height: 100%;

      .listBox {
        width: 95%;
        height: 170px;
        margin: 0 auto;

        .listData {
          width: 100%;
          display: flex;
          align-items: center;
          height: 35px;
          justify-content: space-between;
          //border-bottom: 1px solid #d3d3d3;

          .Ranking {
            color: #FEA903;
            font-size: 10px;
          }

          .siteWarin {
            width: 50%;
            cursor: pointer;

            .ptext {
              padding-left: 5px;
              max-width: 20% !important;

              p {
                max-width: 20% !important;
              }
            }

            //width:100px;
            //text-overflow:ellipsis;
            //overflow:hidden;
            //white-space:nowrap;
            //font-size: 12px;
          }

          .frequency {
            color: #FEA903;
            font-size: 12px;
            padding-right: 10px;
          }
        }

        .listData:last-child {
          border: none;
        }

      }

      .precView {
        width: 95%;
        height: 40px;
        //background-color: rebeccapurple;
        //margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-pagination {
          /deep/ .btn-prev {
            background: none !important;
            border: none !important;
            color: #FEA903 !important;

            .el-icon {
              color: #FEA903;
              font-size: 18px;
            }
          }

          /deep/ .el-pager {
            li {
              background: none !important;
            }

            .number {
              color: #FFFFFF;
              //background: #ffffff !important;
              //border: 1px solid #0061b2;
            }

            .number.active {
              //background: rgba(255,255,255,.3) !important;
            }

            .number.active + li {
              // background: #0061b2 !important;
              border: none !important;
            }

            li.active {
              color: #FEA903 !important;
            }
          }

          /deep/ .btn-next {
            background: none !important;
            border: none !important;
            color: #FEA903 !important;

            .el-icon {
              color: #FEA903;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
